import React, { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import playSvg from '@images/play.png'
import { Image } from 'react-bootstrap'

function VideoModal(props) {
  const { video_id } = props

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {/* <Modal.Title id='contained-modal-title-vcenter'>

        </Modal.Title> */}
      </Modal.Header>
      <Modal.Body>
        <iframe
          src={`https://www.youtube.com/embed/${video_id}`}
          className="video__youtube-video"
        ></iframe>
      </Modal.Body>
      {/* <Modal.Footer>
        <button onClick={props.onHide}>Close</button>
      </Modal.Footer> */}
    </Modal>
  )
}

const Video = (props) => {
  const [modalShow, setModalShow] = useState(false)

  const { video_id, thumbnail } = props

  return (
    <>
      <VideoModal
        video_id={video_id}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="video" onClick={() => setModalShow(true)}>
        <img className="video__thumbnail" src={thumbnail} />

        <div className="video__overlay"></div>
        <div className="video__play-wrapper">
          <Image fluid={true} src={playSvg} className="video__play" />
        </div>
      </div>
    </>
  )
}

export default Video
