import React from 'react'
import { Image } from 'react-bootstrap'

import Video from '@components/Video/Video'
import cardOneImage from '@images/ico_design.svg'
import cardTwoImage from '@images/Vector.svg'
import cardThreeImage from '@images/ico_build.svg'
import cardFourImage from '@images/ico_operate.svg'
import Button from '@components/Button/Button'
import Wrapper from '@components/Wrapper/Wrapper'
import background from '@images/survey-background.svg'
import { Link } from 'gatsby'
import videoTwo from '@images/video-two.jpg'

const Survey = () => {
  const videoId = 'NFry-Lo1Pdg'
  return (
    <Wrapper>
      <div className="survey">
        <Image src={background} fluid={true} className="survey__background" />
        <div className="survey__content-wrapper">
          <div className="survey__heading headingLegendBold">
            Get ready for the digital construction approach
          </div>
          <div className="survey__text textElement">
            Digital construction connects built environment stakeholders and
            streamlines their work processes across the project lifecycle, using
            digital technologies and data. It covers various stages from design,
            fabrication, on-site assembly to operations and maintenance.
          </div>
          <div className="survey__card-container">
            <div className="survey__card-wrapper">
              <div className="survey__card">
                <Image fluid={true} src={cardOneImage} />
              </div>
              <div className="survey__card-text headingLegendBold ">
                Digital design
              </div>
            </div>
            <div className="survey__card-wrapper">
              <div className="survey__card">
                <Image fluid={true} src={cardTwoImage} />
              </div>
              <div className="survey__card-text headingLegendBold">
                Digital manufacturing and fabrication
              </div>
            </div>
            <div className="survey__card-wrapper">
              <div className="survey__card">
                <Image fluid={true} src={cardThreeImage} />
              </div>
              <div className="survey__card-text headingLegendBold">
                Digital construction
              </div>
            </div>
            <div className="survey__card-wrapper">
              <div className="survey__card">
                <Image fluid={true} src={cardFourImage} />
              </div>
              <div className="survey__card-text headingLegendBold">
                Digital asset delivery and management
              </div>
            </div>
          </div>
          <div className="survey__text textElement">
            This Digital Construction Readiness Assessment is free to use.
            Taking only 5 minutes to complete, it is a short survey that
            benchmarks your existing processes against digital construction best
            practice. You will receive a report* providing recommendations on
            how to improve in five essential use cases across the digital
            delivery value chain. The report will also outline the potential
            savings that can be unlocked by adopting cloud solutions recommended
            by the Building and Construction Authority of Singapore.
          </div>
          <div className="survey__video-wrapper">
            <Video video_id={videoId} thumbnail={videoTwo} />
          </div>
          <div className="survey__button-wrapper">
            <Link to="/user-info">
              <Button label="Start Assessment &nbsp;&nbsp;&rarr;" />
            </Link>
          </div>
          <br />
          <br />
          <br />
          <br />
          <div className="survey__text textElement">
            *Based on the Integrated Digital Delivery framework recommended by
            the Building and Construction Authority of Singapore
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default Survey
